import { useQueryClient, useMutation } from "@tanstack/react-query";
import ImagesService from "../service/ImagesService";
import { useNotification } from "../context/NotificationContext";

export function useSetPrimaryImage(workId) {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const setPrimaryImageMutation = useMutation(
    async (imageUrl) => {
      const imageId = queryClient
        .getQueryData(["secondaryImages", workId])
        .find((image) => image.url === imageUrl).id;
      await ImagesService.setPrimaryImage(imageId);
    },
    {
      onSuccess: async () => {
        addNotification("Image changed", "success");
        await queryClient.invalidateQueries(["work", workId]);
        await queryClient.resetQueries(["secondaryImages", workId]);
      },

      onError: (error) => {
        // Gestisci l'errore
        addNotification(error.message, "error");
        console.error("Error deleting image:", error);
      },
    },
  );

  return {
    setPrimaryImage: setPrimaryImageMutation.mutateAsync,
    isSetting: setPrimaryImageMutation.isLoading,
  };
}
