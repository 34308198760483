import { createContext, useContext, useState } from "react";

const SearchContext = createContext();

function SearchProvider({ children }) {
  const initialFilters = {
    artist: "",
    catalogue: "",
    category: "",
    exhibitions: "",
    from: "",
    location: { query: "", exactMatch: false },
    school: "",
    title: "",
    destination: "",
    to: "",
    ownership: "",
  };
  const [filters, setFilters] = useState(initialFilters);
  const [searchAllParam, setSearchAllParam] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [numItemsPerPage, setNumItemsPerPage] = useState(10); // Nuovo stato per i dati filtrati
  const [scrollPosition, setScrollPosition] = useState(0); // Stato per la posizione dello scroll

  function resetFilters() {
    setFilters(initialFilters);
    setSearchAllParam("");
    setCurrentPage(0);
  }

  function onChangePage(selectedPage) {
    setCurrentPage(selectedPage);
  }

  return (
    <SearchContext.Provider
      value={{
        onSearchAll: setSearchAllParam,
        onFilter: setFilters,
        onResetFilter: resetFilters,
        filters: filters,
        searchAllParam: searchAllParam,
        currentPage: currentPage,
        setCurrentPage: onChangePage,
        scrollPosition,
        setScrollPosition,
        numItemsPerPage,
        setNumItemsPerPage,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
function useSearch() {
  const context = useContext(SearchContext);
  return context;
}
export { SearchProvider, useSearch };
