import { useDeleteImage } from "../data/useDeleteImage";

const ThumbnailGallery = ({
  currentImageIndex,
  onThumbnailClick,
  isLoading = false,
  images,
  workId,
}) => {
  const { deleteImage } = useDeleteImage(workId);
  const handleDelete = async (imageUrl) => {
    try {
      await deleteImage(imageUrl);
      // Aggiorna la galleria di miniature per rimuovere l'immagine eliminata
    } catch (error) {
      console.error("Errore durante l'eliminazione dell'immagine:", error);
    }
  };

  return (
    <div className="mt-4 flex justify-center space-x-2">
      {isLoading && (
        <>
          <div className="h-16 w-16 animate-pulse rounded bg-gray-200"></div>
          <div className="h-16 w-16 animate-pulse rounded bg-gray-200"></div>
          <div className="h-16 w-16 animate-pulse rounded bg-gray-200"></div>
        </>
      )}

      {images.map((image, index) => {
        return (
          !isLoading && (
            <div className="group relative h-32 w-32" key={index}>
              {index === 0 && (
                <i className="fas fa-star absolute left-2 top-2 text-yellow-200"></i>
              )}
              <img
                key={index}
                src={image}
                alt=""
                className={`h-32 w-32 cursor-pointer border-2 ${
                  index === currentImageIndex
                    ? "border-blue-500"
                    : "border-transparent"
                }`}
                onClick={() => onThumbnailClick(index)}
              ></img>
              {index !== 0 && (
                <button
                  onClick={() => handleDelete(image)}
                  className="absolute right-2 top-1 text-red-500 opacity-0 duration-300 hover:opacity-100 hover:transition-all hover:ease-in-out"
                  title="Elimina immagine"
                  disabled={isLoading}
                >
                  <i className="fas fa-trash fa-lg"></i>
                </button>
              )}
            </div>
          )
        );
      })}
    </div>
  );
};

export default ThumbnailGallery;
