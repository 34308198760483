import React, { useEffect, useState } from "react";
import ImageSlider from "./ImageSlider";
import ThumbnailGallery from "./ThumbnailGallery";
import useSecondaryImages from "../data/useSecondaryImages";
import { useImage } from "../data/useImage";
import { useSingleWork } from "../data/useSingleWork";
import { ImageUploadForm } from "./ImageUploadForm";

const ImageGallery = ({ workId }) => {
  const { item, isLoading: isLoadingItem } = useSingleWork(workId);
  const { image: primaryImage, isLoading: isLoadingPrimary } = useImage(
    item?.photoIds.find((image) => image.primary)?.id || "",
  );

  const { images: secondaryImages, isLoading: isLoadingSecondary } =
    useSecondaryImages(workId);

  const galleryImages = [
    primaryImage || "/img/no-image.jpeg",
    ...(secondaryImages?.map((image) => image.url) || []),
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleThumbnailClick = (index) => {
    if (index === currentImageIndex || isTransitioning) return;
    startTransition(() => setCurrentImageIndex(index));
  };

  useEffect(() => {
    setCurrentImageIndex(0);
  }, [primaryImage]);

  const startTransition = (updateIndex) => {
    setIsTransitioning(true);
    setTimeout(() => {
      updateIndex();
      setIsTransitioning(false);
    }, 250); // Duration of the transition
  };

  return (
    <div className="relative">
      <ImageSlider
        image={galleryImages[currentImageIndex % galleryImages?.length] || ""}
        isTransitioning={isTransitioning}
        isLoading={isLoadingPrimary || isLoadingItem}
        isPrimary={currentImageIndex === 0}
      />

      {(isLoadingSecondary || secondaryImages?.length > 0) && (
        <ThumbnailGallery
          images={galleryImages}
          workId={workId}
          isLoading={isLoadingSecondary || isLoadingPrimary || isLoadingItem}
          currentImageIndex={currentImageIndex}
          onThumbnailClick={handleThumbnailClick}
        />
      )}

      <ImageUploadForm workId={workId} />
    </div>
  );
};

export default ImageGallery;
