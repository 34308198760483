import { useNavigate } from "react-router-dom";
import { useWorksData } from "../data/useWorksData";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
} from "@heroicons/react/outline";

import AttachmentView from "./AttachmentView";
import { useArtists } from "../data/useArtists";
import { useSchools } from "../data/useSchools";
import { useExhibitions } from "../data/useExhibitions";
import { useEditWorksData } from "../data/useEditWorksData";
import { useSingleWork } from "../data/useSingleWork";
import PrintWorks from "./PrintWorks";
import DeleteWorkModal from "./DeleteWorkModal";
import AttachmentUploadForm from "./AttchmentUploadForm";

export default function WorkInfo({ id }) {
  const { item, isLoading: isLoadingItem } = useSingleWork(id);
  const navigate = useNavigate();
  const { works: data } = useWorksData();
  const { artists } = useArtists();
  const { schools } = useSchools();
  const { exhibitions } = useExhibitions();
  const { isEditing: loading, editWork } = useEditWorksData();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { schoolId: item?.school?.id || "" } });

  const [isEditing, setIsEditing] = useState(false); // Sostituisci con la variabile corretta
  const [expandedInfo, setExpandedInfo] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedArtist = isEditing && watch("artistId");
  const initialValues = {
    catalogue: item?.catalogue,
    location: item?.location,
    artistId: item?.artist?.id,
    title: item?.title,
    titleEn: item?.titleEn,
    schoolId: item?.school?.id || "",
    attribution: item?.attribution,
    dimension: item?.dimension,
    medium: item?.medium,
    frame: item?.frame,
    condition: item?.condition,
    literature: item?.literature,
    artwork: item?.artwork,
    pastExhibition: item?.pastExhibition,
    ownership: item?.ownership,
    temporaryLocation: item?.temporaryLocation,
    provenance: item?.provenance,
    destination: item?.destination,
    futureNote: item?.futureNote,
    pastNote: item?.pastNote,
    exhibitionIds: item?.exhibitions ? item.exhibitions.map((ex) => ex.id) : [],
  };

  const index = data.findIndex((work) => work.id === item?.id);
  //

  const handleClickNext = () => {
    const nextId = data[index + 1]?.id;
    if (nextId) {
      navigate(`/work/${nextId}`);
    }
  };

  const handleClickPrev = () => {
    const prevId = data[index - 1]?.id;
    if (prevId) {
      navigate(`/work/${prevId}`);
    }
  };

  const handleExpandedInfoClick = () => {
    setExpandedInfo((expandedInfo) => !expandedInfo);
  };

  const toggleExhibitionSelection = (id) => {
    const exhibitionIds = getValues("exhibitionIds") || [];
    if (exhibitionIds.includes(id)) {
      setValue(
        "exhibitionIds",
        exhibitionIds.filter((exhibitionId) => exhibitionId !== id),
      );
    } else {
      setValue("exhibitionIds", [...exhibitionIds, id]);
    }
  };

  const handleFormSubmit = (data) => {
    if (JSON.stringify(data) !== JSON.stringify(initialValues)) {
      editWork({ workId: item.id, updatedWorkData: data });
    }
    setIsEditing(false);
    // Passa i dati al genitore tramite la funzione onSubmit
  };

  const handleEditClick = () => {
    setIsEditing(true);
    reset(initialValues); // Passa i dati al genitore tramite la funzione onSubmit
  };

  const handleCancel = () => {
    // Reimposta i valori del form ai valori iniziali
    reset(initialValues);
    // Disattiva la modalità di modifica
    setIsEditing(false);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  /*   if (!item) {
    return null;
  } */

  return (
    <>
      <DeleteWorkModal isOpen={isModalOpen} onRequestClose={closeModal} />

      {!isEditing && (
        <div className="mt-8 flex justify-center gap-4">
          <PrintWorks itemsToPrint={[item]} text="Print" />

          <button
            className="relative w-44 rounded border border-gray-300 bg-slate-100 p-1 px-8 font-semibold hover:bg-slate-500 hover:text-white focus:outline-none active:bg-slate-700 disabled:bg-slate-700 disabled:text-white disabled:opacity-50"
            onClick={handleEditClick}
          >
            Edit Work
          </button>

          <button
            className="relative w-44 rounded border border-gray-300 bg-slate-100 p-1 px-8 font-semibold hover:bg-slate-500 hover:text-white focus:outline-none active:bg-slate-700 disabled:bg-slate-700 disabled:text-white disabled:opacity-50"
            onClick={openModal}
          >
            Delete Work
          </button>
        </div>
      )}
      <form
        className="flex flex-col items-center"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        {isEditing && (
          <div className="flex justify-center">
            <button
              className="mr-4 mt-8 w-24 rounded border border-gray-500 bg-red-300 py-1 font-semibold text-gray-600 hover:bg-red-500 hover:text-gray-200"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className={`mt-8 w-24 rounded border border-gray-500 bg-green-300 py-1 font-semibold text-gray-600 hover:bg-green-600 hover:text-gray-200`}
              type="submit"
            >
              Save
            </button>
          </div>
        )}

        <div className="mx-auto mt-6 grid w-screen max-w-screen-lg grid-flow-row justify-center rounded-lg bg-white p-6 drop-shadow-2xl md:grid-cols-2 md:gap-4">
          <div className="flex items-center justify-start gap-4">
            {data.findIndex((work) => work.id === item?.id) > 0 &&
              !isEditing && (
                <ArrowCircleLeftIcon
                  onClick={handleClickPrev}
                  className="h-7 w-7 cursor-pointer text-neutral-600"
                />
              )}
            <span className="text-lg font-semibold tracking-tight text-stone-600">
              Catalogue:{" "}
            </span>
            {isEditing ? (
              <div className="">
                <input
                  {...register("catalogue", {
                    required: "Catalogue is required",
                  })}
                  className={`border p-1 ${errors.catalogue ? "border-red-800 focus:outline-none" : ""}`}
                  disabled={!isEditing}
                />
                {errors.catalogue && (
                  <span className="text-base text-red-800">
                    {errors.catalogue.message}
                  </span>
                )}
              </div>
            ) : (
              <span>{item?.catalogue}</span>
            )}

            {!isEditing && (
              <ArrowCircleRightIcon
                onClick={handleClickNext}
                className="h-7 w-7 cursor-pointer text-neutral-600"
              />
            )}
          </div>
          <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
            <span className="font-semibold">Location: </span>
            {isEditing ? (
              <input
                {...register("location")}
                className="flex-grow border p-1"
                disabled={!isEditing}
              />
            ) : (
              <span>{item?.location}</span>
            )}
          </div>

          <div className="flex items-start gap-4 text-lg tracking-tight text-stone-600">
            <span className="font-semibold">Artist: </span>
            {isEditing ? (
              <select
                {...register("artistId")}
                className="w-full flex-grow border p-1"
                disabled={!isEditing}
                defaultValue={item?.artist?.id}
              >
                {artists.map((artist) => (
                  <option value={artist.id} key={artist.id}>
                    {artist.name}
                  </option>
                ))}
              </select>
            ) : (
              <span>{item?.artist?.name}</span>
            )}
          </div>

          <div className="flex items-start gap-4 text-lg tracking-tight text-stone-600">
            <span className="flex-shrink-0 font-semibold">Artist Info: </span>
            {isEditing ? (
              <input
                name="artistInfo"
                value={
                  artists.find((artist) => artist.id === selectedArtist)
                    ?.artistInfo
                }
                className="flex-grow overflow-x-visible border p-1"
                disabled={true}
              />
            ) : (
              <span>{item?.artist?.artistInfo}</span>
            )}
          </div>
          <div className="flex items-start gap-4 text-lg tracking-tight text-stone-600">
            <span className="font-semibold">Title: </span>
            {isEditing ? (
              <div>
                <input
                  {...register("title", { required: "Title is required" })}
                  className={`flex-grow overflow-x-visible border p-1 ${errors.catalogue ? "border-red-800 focus:outline-none" : ""}`}
                  disabled={!isEditing}
                />
              </div>
            ) : (
              <span>{item?.title}</span>
            )}
          </div>
          <div className="flex items-start gap-4 text-lg tracking-tight text-stone-600">
            <span className="font-semibold">Title En: </span>
            {isEditing ? (
              <input
                {...register("titleEn")}
                className="flex-grow overflow-x-visible border p-1"
                disabled={!isEditing}
              />
            ) : (
              <span>{item?.titleEn}</span>
            )}
          </div>
          <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
            <span className="font-semibold">School: </span>
            {isEditing ? (
              <select
                {...register("schoolId")}
                className="w-full flex-grow border p-1"
              >
                <option value="">(None)</option>

                {schools?.map((school) => (
                  <option value={school.id} key={school.id}>
                    {school.description}
                  </option>
                ))}
              </select>
            ) : (
              <span>{item?.school?.description}</span>
            )}
          </div>

          <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
            <span className="font-semibold">Attribution: </span>
            {isEditing ? (
              <input
                {...register("attribution")}
                className="flex-grow overflow-x-visible border p-1"
                disabled={!isEditing}
              />
            ) : (
              <span>{item?.attribution}</span>
            )}
          </div>
          <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
            <span className="font-semibold">Dimension: </span>
            {isEditing ? (
              <input
                {...register("dimension")}
                className="flex-grow overflow-x-visible border p-1"
                disabled={!isEditing}
              />
            ) : (
              <span>{item?.dimension}</span>
            )}
          </div>
          <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
            <span className="font-semibold">Medium:</span>
            {isEditing ? (
              <input
                {...register("medium")}
                className="flex-grow overflow-x-visible border p-1"
                disabled={!isEditing}
              />
            ) : (
              <span>{item?.medium}</span>
            )}
          </div>
          <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
            <span className="font-semibold">Frame:</span>
            {isEditing ? (
              <input
                {...register("frame")}
                className="flex-grow overflow-x-visible border p-1"
                disabled={!isEditing}
              />
            ) : (
              <span>{item?.frame}</span>
            )}
          </div>
          <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
            <span className="font-semibold">Condition:</span>
            {isEditing ? (
              <input
                {...register("condition")}
                className="flex-grow overflow-x-visible border p-1"
                disabled={!isEditing}
              />
            ) : (
              <span>{item?.condition}</span>
            )}
          </div>
          <div className="flex flex-col text-lg tracking-tight text-stone-600 md:col-span-2">
            <span className="font-semibold">Literature:</span>
            {isEditing ? (
              <textarea
                {...register("literature")}
                className="flex-grow overflow-x-visible border p-1"
                disabled={!isEditing}
              />
            ) : (
              item?.literature && (
                <span>
                  {item?.literature?.split("\n").map((part) => (
                    <>
                      {part}
                      <br />
                    </>
                  ))}
                </span>
              )
            )}
          </div>
          <div className="flex flex-col text-lg tracking-tight text-stone-600 md:col-span-2">
            <span className="font-semibold">Info ArtWork:</span>
            {isEditing ? (
              <textarea
                {...register("artwork")}
                className="flex-grow overflow-x-visible border p-1"
                disabled={!isEditing}
              />
            ) : (
              item?.artwork && (
                <span>
                  {item?.artwork?.split("\n").map((part) => (
                    <>
                      {part}
                      <br />
                    </>
                  ))}
                </span>
              )
            )}
          </div>
          <div className="flex flex-col text-lg tracking-tight text-stone-600 md:col-span-2">
            <span className="flex-shrink-0 font-semibold">
              Past Exhibitions:
            </span>
            {isEditing ? (
              <textarea
                {...register("pastExhibition")}
                className="flex-grow overflow-x-visible border p-1"
                disabled={!isEditing}
              />
            ) : (
              item?.pastExhibition && (
                <span>
                  {item?.pastExhibition?.split(/\r\n|\r|\n/).map((part) => (
                    <>
                      {part}
                      <br />
                    </>
                  ))}
                </span>
              )
            )}
          </div>

          <div className="text-lg font-semibold tracking-tight text-stone-600 md:col-span-2">
            <span>Exhibitions:</span>
            {isEditing ? (
              <div className="mt-2 h-40 overflow-y-scroll border p-2">
                {exhibitions.map((exhibition) => (
                  <div key={exhibition.id} className="mb-2 font-normal">
                    <input
                      type="checkbox"
                      id={exhibition.id}
                      value={exhibition.id}
                      {...register("exhibitionIds")}
                      checked={watch("exhibitionIds")?.includes(exhibition.id)}
                      onChange={() => toggleExhibitionSelection(exhibition.id)}
                    />
                    <label
                      className="ml-2 tracking-normal"
                      htmlFor={exhibition.id}
                    >
                      {exhibition.title}
                    </label>
                  </div>
                ))}
              </div>
            ) : (
              <ul className="mt-0 text-lg font-normal tracking-normal">
                {item?.exhibitions.map((exhibition, index) => (
                  <li className="font-normal tracking-normal" key={index}>
                    {exhibition.title}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="flex items-start gap-4 text-lg tracking-tight text-stone-600">
            <AttachmentView item={item} isEditing={isEditing} />
            {isEditing && <AttachmentUploadForm workId={item.id} />}
          </div>

          {expandedInfo ? (
            <>
              <div className="col-span-2 flex justify-start">
                <button
                  className="rounded border border-gray-500 bg-gray-300 px-2 py-1 text-gray-700"
                  onClick={handleExpandedInfoClick}
                >
                  Less Info -
                </button>
              </div>
              <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
                <span className="font-semibold">Ownership:</span>
                {isEditing ? (
                  <input
                    {...register("ownership")}
                    className="flex-grow overflow-x-visible border p-1"
                    disabled={!isEditing}
                  />
                ) : (
                  <span>{item?.ownership}</span>
                )}
              </div>
              <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
                <span className="font-semibold">Temporary Location:</span>
                {isEditing ? (
                  <input
                    {...register("temporaryLocation")}
                    className="flex-grow overflow-x-visible border p-1"
                    disabled={!isEditing}
                  />
                ) : (
                  <span>{item?.temporaryLocation}</span>
                )}
              </div>
              <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
                <span className="font-semibold">Provenance:</span>
                {isEditing ? (
                  <input
                    {...register("provenance")}
                    className="flex-grow overflow-x-visible border p-1"
                    disabled={!isEditing}
                  />
                ) : (
                  <span>{item?.provenance}</span>
                )}
              </div>
              <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
                <span className="font-semibold">Destination:</span>
                {isEditing ? (
                  <input
                    {...register("destination")}
                    className="flex-grow overflow-x-visible border p-1"
                    disabled={!isEditing}
                  />
                ) : (
                  <span>{item?.destination}</span>
                )}
              </div>

              <div className="flex flex-col text-lg tracking-tight text-stone-600 md:col-span-2">
                <span className="font-semibold">Past Notes:</span>
                {isEditing ? (
                  <textarea
                    {...register("pastNote")}
                    className="flex-grow overflow-x-visible border p-1"
                    disabled={!isEditing}
                  />
                ) : (
                  item?.pastNote && (
                    <span>
                      {item?.pastNote?.split("\n").map((part) => (
                        <>
                          {part}
                          <br />
                        </>
                      ))}
                    </span>
                  )
                )}
              </div>
              <div className="flex flex-col text-lg tracking-tight text-stone-600 md:col-span-2">
                <span className="font-semibold">Future Notes:</span>
                {isEditing ? (
                  <textarea
                    {...register("futureNote")}
                    className="flex-grow overflow-x-visible border p-1"
                    disabled={!isEditing}
                  />
                ) : (
                  item?.futureNote && (
                    <span>
                      {item?.futureNote?.split("\n").map((part) => (
                        <>
                          {part}
                          <br />
                        </>
                      ))}
                    </span>
                  )
                )}
              </div>
            </>
          ) : (
            <div className="col-span-2 flex justify-start">
              <button
                className="rounded border border-gray-500 bg-gray-300 px-2 py-1 text-gray-700"
                onClick={handleExpandedInfoClick}
              >
                More Info +
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  );
}
