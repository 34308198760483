import { useState, useMemo, useCallback } from "react";

// Custom hook per la logica di fetching e filtraggio
export function useDropdown({ getData, value }) {
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const remoteData = await getData();
      setData(remoteData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [getData]);

  // Aggiorna il filtraggio dei dati basato sull'input
  const filteredData = useMemo(() => {
    return data?.filter((item) =>
      (item?.description || item?.name || String(item))
        .toLowerCase()
        .includes(value.toLowerCase()),
    );
  }, [value, data]);

  return {
    filteredData,
    fetchData,
  };
}
