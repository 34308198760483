import { useState, useEffect } from "react";
import { useSearchAll } from "./useSearchAll";
import { useSearch } from "../context/SearchContext";

export const useFilter = () => {
  const {
    searchAllData: initialData,
    isLoading: isSearching,
    error,
  } = useSearchAll();

  const { filters: searchParams } = useSearch();
  const [filteredData, setFilteredData] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);

  useEffect(() => {
    setIsFiltering(true);
    if (!Object.values(searchParams).some((value) => value !== "")) {
      setFilteredData(initialData);
      setIsFiltering(false);
      return;
    }

    const newFilteredData = initialData?.filter((item) => {
      // Per ciascun degli item iniziali, controlla se tutti i criteri di ricerca corrispondono
      for (const key in searchParams) {
        // Se il campo di ricerca è vuoto, vado avanti
        if (!searchParams[key]) {
          continue;
        }

        // Applica il criterio di ricerca per il campo corrente
        // Se anche uno dei criteri non è soddisfatto, l'item viene escluso dai risultati
        if (!searchCriteria(key, item, searchParams[key])) {
          return false;
        }
      }

      // Se tutti i criteri di ricerca corrispondono, includi l'item nel risultato
      return true;
    });
    setFilteredData(newFilteredData);
    setIsFiltering(false);
  }, [initialData, searchParams]);

  return { filteredData, isLoading: isSearching || isFiltering, error };
};

function searchCriteria(key, item, searchValue) {
  // Implementa i criteri di ricerca specifici per ciascun campo
  switch (key) {
    case "catalogue":
      return Number(item.catalogue) === Number(searchValue);
    case "artist":
      return item[key]?.name.toLowerCase().includes(searchValue.toLowerCase());
    case "title":
      return (
        item[key]?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item["titleEn"]?.toLowerCase().includes(searchValue.toLowerCase())
      );
    case "location":
      if (!searchValue.exactMatch) {
        return item[key]
          ?.toUpperCase()
          .includes(searchValue.query?.toUpperCase());
      } else
        return item[key]?.toUpperCase() === searchValue.query?.toUpperCase();
    case "school":
      // Criterio di ricerca per stringhe: controlla se il valore dell'oggetto include il valore di ricerca
      return item[key]?.description
        ?.toLowerCase()
        .includes(searchValue.toLowerCase());
    case "exhibitions":
      // Criterio di ricerca per stringhe: controlla se il valore dell'oggetto include il valore di ricerca
      return item[key]?.some((item) =>
        item.title?.toLowerCase().includes(searchValue.toLowerCase()),
      );
    case "destination":
      return item[key]?.toLowerCase().includes(searchValue.toLowerCase());
    case "ownership":
      return item[key]?.toLowerCase().includes(searchValue.toLowerCase());
    case "from":
      // Criterio di ricerca per il campo "to"
      return parseInt(item.catalogue, 10) >= parseInt(searchValue, 10);
    case "to":
      // Criterio di ricerca per il campo "to"
      return parseInt(item.catalogue, 10) <= parseInt(searchValue, 10);
    default:
      return true; // Se non è specificato alcun criterio, includi l'oggetto nel risultato
  }
}
